<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-02-01 10:05:02
 * @LastEditors: guomengxia
 * @LastEditTime: 2025-03-06 16:58:07
-->
<template>
  <div class="addTopic">
    <div class="fx-row content">
      <div class="left">
        <div class="history-version">
          <div>版本：{{currentVersionDate}}</div>
          <el-button type="text" @click="_openHistoryVersion">历史版本</el-button>
          </div>
        <com-title title="基本字段"  v-show="!isOpenHistory"/>
        <div class="sm-tit" v-show="!isOpenHistory">点击添加到表单编辑区</div>
        <div class="sm-tit" v-show="!isOpenHistory">选择分组后，题目需添加在分组中</div>
        <div class="topic-type-list fx-sw-cen" v-show="!isOpenHistory">
          <div v-for="(item,index) in topicTypeList" :key="index" @click="_handlerType(item)">
            <img src="@/assets/img/topic1.png" v-if="item.questionType === 'SINGLE_CHOICE_TYPE'" alt="">
            <img src="@/assets/img/topic2.png" v-if="item.questionType === 'MULTIPLE_CHOICE_TYPE'" alt="">
            <img src="@/assets/img/topic3.png" v-if="item.questionType === 'JUDGMENT_TYPE'" alt="">
            <img src="@/assets/img/topic4.png" v-if="item.questionType === 'GAP_FILLING_TYPE'" alt="">
            <img src="@/assets/img/topic5.png" v-if="item.questionType === 'ESSAY_QUESTION_TYPE'" alt="">
            <img src="@/assets/img/icon_Grouping.png" v-if="item.questionType === 'GROUP_TYPE'" alt="">
            <span>{{ item.questionTypeValue }}</span>
          </div>
        </div>
        <!-- 快速定位--开始 -->
        <div style="display:flex;align-items:center;">
          <com-title title="题目" />
          <span class="sm-tit">可点击序号进行快速定位</span>
        </div>
        <!-- 题目序号--开始 -->
        <div class="quick-position">
          <div v-for="(questionsGroup,questionIndex) in questionsGroupList" :key="questionIndex" class="quick-position-content">
            <div v-if="questionsGroup.groupName" class="position-group-title">{{questionsGroup.groupName}}</div>
            <div class="position-group-content">
              <div v-for="(topicItem, topicIndex) in questionsGroup?.questionInfo" :key="topicIndex" class="position-number" @click="_quickPositionBtn(questionIndex,topicIndex)">
                {{topicIndex+1}}
              </div>
            </div>
          </div>
        </div>
        <!-- 题目序号--结束 -->
      </div>
      <!-- 主要内容---开始 -->
      <div class="middle fx-1" style="padding: 0px 16px 16px 16px">
      <!-- 主要内容头部--开始 -->
        <div class="paper-top-content-main">
          <div class="paper-top-content">
            <div class="paper-top-content-left">
              <div class="topic-title">
                <span>题目总数：</span>
                <span style="color:#0089FF;">{{topicTotalNumber}}</span>
              </div>
              <div class="topic-title">
                <span>题目总分：</span>
                <span style="color:#0089FF;">{{topicTotalScore}}</span>
              </div>
            </div>
            <div class="paper-top-content-right">
              <el-button type="primary" @click="_selectTopicBankBtn" v-show="!isOpenHistory">从题库中选择</el-button>
              <el-button @click="_adjustOrderBtn" style="margin-left:16px;" v-show="!isOpenHistory">调整顺序</el-button>
              <el-button style="margin-left:16px;" @click="_scoreAdjust" v-show="!isOpenHistory">分值调整</el-button>
            </div>
          </div>
        </div>
        <!-- 主要内容头部--结束 -->
        <!-- 主要内容-题目--开始 -->
        <div class="paper-topic-content" v-if="questionsGroupList.length">
          <div v-for="(questionsGroup,index) in questionsGroupList" :key="index" :class="groupIndexActive===index?'groupIndexActiveStyle':'groupIndexDefault'">
            <div v-if="questionsGroup.groupName" @click="_selectGroupName(questionsGroup,index)">
              <div style="display:flex; justify-content:space-between;">
                  <com-title :title="questionsGroup.groupName" />
                  <el-button type="text" @click="_deletePaperGroup(questionsGroup,index)" v-show="!isOpenHistory">删除</el-button>
              </div>
            </div>
            <topic-list style="padding:0px 8px;"
              v-if="questionsGroup?.questionInfo?.length"
              :key="refreshQuestionsGroupListKey"
              noAllowDrag="noAllowDrag"
              :groupIndexActive="groupIndexActive"
              :topicActiveIndexParent="topicActiveIndexParent"
              :isActiveStyle="isActiveStyle"
              :topicList="questionsGroup?.questionInfo||[]"
              @editOption="editOption"
              @deleteTopicItem="_deleteTopicItem"
              @changeTopicType='_changeTopicType'
              :isOpenHistory="isOpenHistory"/>
          </div>
        </div>
        <span style="margin: 20px;" v-else>暂无题目，请添加！</span>
        <!-- 主要内容-题目--结束 -->
      </div>
      <div class="right" v-show="!isOpenHistory">
        <!-- 题目类型---提交题目-->
        <topic-options
          ref="topicOptionRef"
          v-if="chooseTypeItem"
          :topicType="chooseTypeItem"
          :paperGroupNameInfo="paperGroupNameInfo"
          :currentTopicData="currentTopicData"
          @submitOption="_getAddOption"
          @topicGroupNameInput="_topicGroupNameInput"
          @topicTitleInput="_topicTitleInput"
          @topicContentInput="_topicContentInput"
          @topicScoreInput="_topicScoreInput"
          @optionItemInput="_optionItemInput"
          @optionItemAddOrDelete="_optionItemAddOrDelete"
          @radioSelectInput="_radioSelectInput"
          @checkSelectChange="_checkSelectChange"
          @answerInput="_answerInput"
          @analysisInput="_analysisInput"
          @topicPictureUpload="_topicPictureUpload"
          @analysePictureUpload="_analysePictureUpload"
          @otherInstructionsInput="_otherInstructionsInput" />
      </div>
      <!--历史版本数据----开始 -->
      <div class="right" v-if="isOpenHistory">
        <div class="history-box">
          <div class="history-title">
            <div>历史版本</div>
            <div class="icon" @click="_closeHistory"><i class="el-icon-close"></i></div>
          </div>
          <div v-for="(itemDate,indexDate) in historyRecords" :key="indexDate" class="history-item">
          <div class="history-item-date">
            <span v-if="!itemDate.isShow" @click="itemDate.isShow=true"><i class="el-icon-caret-right"></i></span>
            <span v-if="itemDate.isShow" @click="itemDate.isShow=false"><i class="el-icon-caret-bottom"></i></span>
            <span>{{itemDate.dateTime}}</span>
          </div>
          <div v-if="itemDate.isShow" class="history-item-child">
            <div v-for="(itemChild,indexChild) in itemDate.historyList" :key="indexChild">
              <div class="child-current" v-if="indexDate==0&&indexChild==0">当前版本</div>
              <div class="child-item" @mouseenter="_handleMouseEnter(itemChild.id)" @mouseleave="_handleMouseLeave(itemChild.id)">
                <div @click="_viewHistoryRecord(itemChild.id)">{{itemChild.createTime}} {{itemChild.content}}</div>
                <el-button type="text" @click="_restoreHistoryPaper(itemChild.id)"  style="display:none" :class="`paper-restore-btn${itemChild.id}`">还原</el-button>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <!--历史版本数据----结束 -->
    </div>
    <div class="footer-btn" v-show="!isOpenHistory">
      <el-button @click="_previewPaper">预览</el-button>
      <el-button type="primary" @click="_savePaperTopicData()">保存</el-button>
    </div>
    <!-- 调整顺序弹框---开始 -->
    <adjustOrderDrawer
      v-if="adjustOrderShow"
      :adjustOrderShow="adjustOrderShow"
      :questionsGroupList="questionsGroupList"
      @adjustTopicOrder="_adjustTopicOrder"
    ></adjustOrderDrawer>
    <!-- 调整顺序弹框---结束 -->
    <!-- 从题库中选择--弹框--开始 -->
    <selectTopicDrawer
      v-if="selectTopicShow"
      :selectTopicShow="selectTopicShow"
      @selectQuestionData="_selectQuestionData"
      :direction="direction"
      :drawerSize="drawerSize"
    ></selectTopicDrawer>
    <!-- 从题库中选择--弹框--结束 -->
    <!-- 预览试卷--弹框--开始 -->
    <previewExamPaper
      v-if="previewExamShow"
      :previewExamShow="previewExamShow"
      :paperId="paperId"
      :paperName="paperName"
      :questionsGroupList="questionsGroupList"
    ></previewExamPaper>
    <!-- 预览试卷--弹框--结束 -->
    <!-- 分值调整--开始 -->
    <scoreAdjustDrawer
      v-if="scoreAdjustShow"
      :scoreAdjustShow="scoreAdjustShow"
      :questionsGroupList="questionsGroupList"
      @scoreAdjust="_scoreAdjustConfirm"
    ></scoreAdjustDrawer>
    <!-- 分值调整--结束 -->
  </div>
</template>
<script>
import TopicList from '@/views/examManage/testBaseManage/components/topicModule/topicList.vue'
import TopicOptions from '@/views/examManage/testBaseManage/components/topicModule/topicOptions.vue'
import selectTopicDrawer from '../modules/selectTopicDrawer.vue'
import scoreAdjustDrawer from '../modules/scoreAdjustDrawer.vue'
import previewExamPaper from '../modules/previewExamPaper.vue'
import adjustOrderDrawer from '../modules/adjustOrderDrawer.vue'
import comTitle from '@/components/comTitle'
import { getPaperContentEditApi, getPaperDetailApi, getPaperHistoryRecordApi, getHistoryPaperByIdApi, getVersionRestorePaperApi } from '@/api/examApi'
import paperDelete from '@/mixins/paperDelete'
import { getCurrentTime } from '@/utils/util'
export default {
  components: { TopicList, TopicOptions, comTitle, selectTopicDrawer, scoreAdjustDrawer, previewExamPaper, adjustOrderDrawer },
  props: {
    paperName: {
      type: String,
      default: ''
    }
  },
  mixins: [paperDelete],
  data () {
    return {
      chooseTypeItem: null, // 选中的类型
      optionList: [],
      editOptionIndex: null,
      topicTypeList: [
        { questionType: 'SINGLE_CHOICE_TYPE', questionTypeValue: '单项选择' },
        { questionType: 'MULTIPLE_CHOICE_TYPE', questionTypeValue: '多项选择' },
        { questionType: 'JUDGMENT_TYPE', questionTypeValue: '判断题' },
        { questionType: 'GAP_FILLING_TYPE', questionTypeValue: '填空题' },
        { questionType: 'ESSAY_QUESTION_TYPE', questionTypeValue: '问答题' },
        { questionType: 'GROUP_TYPE', questionTypeValue: '分组' }
      ],
      paperId: '',
      list: [],
      // 单项选择默认数据
      selectListData: {
        questionBankId: this.$route.query.questionBankId,
        questionType: 'SINGLE_CHOICE_TYPE', // 题类型--单项选择
        questionTypeValue: '单项选择',
        title: '题目', // 题目
        content: '题目说明', // 题目说明
        contentUrl: '', // 说明图片
        score: 5, // 分数
        options: [
          { optionName: '选项一', label: '选项1' },
          { optionName: '选项二', label: '选项2' },
          { optionName: '选项三', label: '选项3' }
        ], // 选项1
        answer: '选项1', // 答案
        analysis: '试题答案解析', // 答案解析
        analysisUrl: '', // 答案解析图片
        otherInstructions: '' // 其他说明
      },
      currentTopicData: {}, //
      questionsGroupList: [], // 题目分组
      paperGroupNameInfo: {},
      groupIndexActive: null,
      topicActiveIndexParent: null,
      adjustOrderShow: false,
      selectTopicShow: false,
      drawerSize: '65%',
      direction: 'rtl',
      scoreAdjustShow: false, // 分值调整弹框
      previewExamShow: false, // 预览试卷弹框
      isFirstSelectType: '',
      divideGroupArr: {
        groupName: '',
        groupIndex: 0,
        questionInfo: []
      },
      refreshQuestionsGroupListKey: new Date().getTime(),
      selectTopicListArr: [],
      selectTopicListArrId: [],
      isActiveStyle: true,
      topicTotalNumber: 0, // 题目总数
      topicTotalScore: 0, // 题目总分
      paperDetailInfo: {}, // 详情数据
      questionTopicArr: [],
      questionsSingleGroupCopy: [], // 单个删除的分组
      isOpenHistory: false,
      historyRecords: [], // 历史记录数据
      currentVersionDate: ''
    }
  },
  watch: {
    'chooseTypeItem.questionTypeValue' (newValue, oldValue) {
      if (newValue === '分组') {
        this.isActiveStyle = false
      } else {
        this.isActiveStyle = true
      }
    },
    questionsGroupList: {
      handler (newVal, oldVal) {
        console.log(newVal, oldVal, 'newVal, oldVal')
        // 监听后的操作
      },
      deep: true // 加这个属性，深度监听
    },
    isOpenHistory: {
      handler (newVal, oldVal) {
        if (newVal) {
          const paperRestoreBtn = document.querySelector('.paper-restore-btn')
          if (paperRestoreBtn) {
            paperRestoreBtn.style.display = 'none'
          }
        }
      }
    }
  },
  created () {
    // 默认单选框
    this.chooseTypeItem = this.topicTypeList[0]
    this.optionList[0] = this._getTopicDataByType(this.chooseTypeItem.questionType)
    // console.log(this.$route.query.paperId, 'this.$route.query.paperId')
    this.paperId = this.$route.query.paperId
    this.paperName = this.$route.query.name
    this._getCurrentPaperDetail()
    if (this.$route.query.paperId) {
      this._getHistoryRecord(this.$route.query.paperId)
    }
  },
  methods: {
    // 获取历史记录
    _getHistoryRecord (paperId, type) {
      getPaperHistoryRecordApi({
        businessId: paperId,
        businessType: 5
      }).then((res) => {
        if (res.code === 200) {
          this.historyRecords = JSON.parse(JSON.stringify(res.data))
          this.historyRecords = this.historyRecords.map((item, index) => {
            return {
              ...item,
              isShow: index === 0// 等于为true,不是第一个不展开
            }
          })
          console.log(this.historyRecords, 'this.historyRecords11')
          if (this.historyRecords.length > 0) {
            this.currentVersionDate = `${this.historyRecords[0].dateTime} ${this.historyRecords[0].historyList[0].createTime}`
          } else {
            this.currentVersionDate = getCurrentTime().substring(0, 16)
          }
          if (type === '还原') {
            this._getCurrentPaperDetail()
            this.isOpenHistory = false
          }
        }
        console.log(res, '历史记录212')
      })
    },
    // 打开历史版本
    _openHistoryVersion () {
      this.isOpenHistory = true
    },
    // 关闭历史版本
    _closeHistory () {
      this.isOpenHistory = false
      this._getCurrentPaperDetail()
    },
    // 查看历史版本
    _viewHistoryRecord (historyId) {
      getHistoryPaperByIdApi({ id: historyId }).then((res) => {
        if (res.code === 200) {
          this.paperDetailInfo = JSON.parse(JSON.stringify(res.data))
          this.questionTopicArr = JSON.parse(JSON.stringify(res.data.questions))
          this.topicTotalNumber = this.paperDetailInfo.questionNumber// 题目总数量
          // this.topicTotalScore = this.paperDetailInfo.score// 题目总分数
          this._computeTopicNum(this.questionTopicArr)// 计算题目总数量，题目总分数
          if (this.paperDetailInfo.questions.length !== 0) {
            this.paperDetailInfo.questions.forEach((groupInfo, groupIndex) => {
              this._getNeedShowData(this.paperDetailInfo.questions, groupInfo, groupIndex, 'edit')// 回显所需数据格式（整个数组，其中一个整个分组，分组下标）
            })
          }
          if (this.paperDetailInfo?.questions.length === 1 && this.paperDetailInfo?.questions[0]?.groupName === '') {
            // 不分组情况下
            this.isFirstSelectType = 'noDivideGroup'
            this.groupIndexActive = 0
            this.$nextTick(() => {
              this.questionsGroupList = this.paperDetailInfo.questions
              this.divideGroupArr = this.paperDetailInfo.questions[0]
            })
            this.$set(this.questionsGroupList, 0, { ...this.questionsGroupList })// 刷新页面
          } else {
            // 分组情况下---
            this.isFirstSelectType = 'divideGroup'
            this.$nextTick(() => {
              this.questionsGroupList = this.paperDetailInfo.questions
            })
            this.$set(this.questionsGroupList, 'questionInfo', this.paperDetailInfo.questions)// 刷新页面
          }
        }
      })
    },
    // 还原历史试卷
    _restoreHistoryPaper (historyId) {
      this.$confirm('此操作将还原试卷, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        getVersionRestorePaperApi({ id: historyId }).then((res) => {
          if (res.code === 200) {
            this.$message.success('操作成功！')
            this._getHistoryRecord(this.$route.query.paperId, '还原')
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 鼠标移入移出
    _handleMouseEnter (historyId) {
      if (this.historyRecords && this.historyRecords.length) {
        if (historyId === this.historyRecords[0].historyList[0].id) { // 是当前版本时不显示还原按钮
          const paperRestoreBtn = document.querySelector(`.paper-restore-btn${historyId}`)
          paperRestoreBtn.style.display = 'none'
        } else {
          const paperRestoreBtn = document.querySelector(`.paper-restore-btn${historyId}`)
          paperRestoreBtn.style.display = 'block'
        }
      }
    },
    _handleMouseLeave (historyId) {
      const paperRestoreBtn = document.querySelector(`.paper-restore-btn${historyId}`)
      paperRestoreBtn.style.display = 'none'
    },
    // 删除分组
    _deletePaperGroup (group, groupIndex) {
      this._groupDelete(this.questionsGroupList, groupIndex, group.questionInfo)
      const allDeleteTopicArr = []
      this.groupDeleteArr.forEach((groupItem) => {
        groupItem.questionInfo.forEach((item, index) => {
          allDeleteTopicArr.push(item)
        })
      })
      // 删除时把this.selectTopicListArrId中题目id删掉，确保删除后能再次从题库中选择
      this.selectTopicListArr = this.selectTopicListArr.filter(item => {
        return !allDeleteTopicArr.find(prop => {
          return prop.id === item.id
        })
      })
      this.selectTopicListArrId = this.selectTopicListArr.map((item) => {
        return item.id
      })
      this._computeTopicNum(this.questionsGroupList)// 计算题目总数量，题目总分数
    },
    // 列表删除某项
    _deleteTopicItem (data) {
      this._topicDelete(data.topicList, data.index)
      // 删除时把this.selectTopicListArrId中题目id删掉，确保删除后能再次从题库中选择
      const allDeleteTopicArr = []
      this.topicDeleteArr.forEach((topicItem, topicIndex) => {
        allDeleteTopicArr.push(topicItem)
      })
      this.selectTopicListArr = this.selectTopicListArr.filter(item => {
        return !allDeleteTopicArr.find(prop => {
          return prop.id === item.id
        })
      })
      this.selectTopicListArrId = this.selectTopicListArr.map((item) => {
        return item.id
      })
      this._computeTopicNum(this.questionsGroupList)// 计算题目总数量，题目总分数
      // 如果新建首先是不分组情况，在删除不分组下面的所有试题，要保证能够分组
      if ((this.isFirstSelectType === 'noDivideGroup' && this.questionTopicArr[0]?.questionInfo.length === this.topicDeleteArr.length) &&
      (this.questionsGroupList.length === 1 && this.questionsGroupList[0].groupName === '' && this.questionsGroupList[0]?.questionInfo.length === 0)) {
        // 如果新建首先是不分组情况，在删除不分组下面的所有试题，要保证能够分组---在提交之前会处理（此处使用一个questionsSingleGroupCopy备份使用）
        this.isFirstSelectType = 'divideGroup'
        this.groupIndexActive = 1
        if (this.questionsGroupList[0].detailDeleteId !== undefined) {
          this.questionsSingleGroupCopy = JSON.parse(JSON.stringify(this.questionsGroupList))// 单个删除的分组
        }
        this.questionsGroupList = []
      }
    },
    // 获取详情
    _getCurrentPaperDetail () {
      getPaperDetailApi({ id: this.$route.query.paperId }).then((res) => {
        if (res.code === 200) {
          this.paperDetailInfo = JSON.parse(JSON.stringify(res.data))
          this.questionTopicArr = JSON.parse(JSON.stringify(res.data.questions))
          this.topicTotalNumber = this.paperDetailInfo.questionNumber// 题目总数量
          // this.topicTotalScore = this.paperDetailInfo.score// 题目总分数
          this._computeTopicNum(this.questionTopicArr)// 计算题目总数量，题目总分数
          if (this.paperDetailInfo.questions.length !== 0) {
            this.paperDetailInfo.questions.forEach((groupInfo, groupIndex) => {
              this._getNeedShowData(this.paperDetailInfo.questions, groupInfo, groupIndex, 'edit')// 回显所需数据格式（整个数组，其中一个整个分组，分组下标）
            })
          }
          if (this.paperDetailInfo?.questions.length === 1 && this.paperDetailInfo?.questions[0]?.groupName === '') {
            // 不分组情况下
            this.isFirstSelectType = 'noDivideGroup'
            this.groupIndexActive = 0
            this.$nextTick(() => {
              this.questionsGroupList = this.paperDetailInfo.questions
              this.divideGroupArr = this.paperDetailInfo.questions[0]
            })
            this.$set(this.questionsGroupList, 0, { ...this.questionsGroupList })// 刷新页面
          } else {
            // 分组情况下---
            this.isFirstSelectType = 'divideGroup'
            this.$nextTick(() => {
              this.questionsGroupList = this.paperDetailInfo.questions
            })
            this.$set(this.questionsGroupList, 'questionInfo', this.paperDetailInfo.questions)// 刷新页面
          }
        }
      })
    },
    // 回显所需数据格式
    _getNeedShowData (groupTopicList, groupInfo, groupIndex, type) {
      if (type === 'edit') {
        this.selectTopicListArr.push.apply(this.selectTopicListArr, groupTopicList[groupIndex].questionInfo)
        this.selectTopicListArrId = this.selectTopicListArr.map((item) => {
          return item.id
        })
      }
      groupInfo.groupIndex = groupIndex
      groupInfo.detailDeleteId = (type === 'edit' || groupInfo.detailDeleteId !== undefined) ? groupInfo.groupIndex : undefined
      groupTopicList[groupIndex].questionInfo = groupTopicList[groupIndex].questionInfo.map((topicItem, topicIndex) => {
        return {
          ...topicItem,
          detailDeleteId: (type === 'edit' || groupInfo.detailDeleteId !== undefined) ? topicItem.id : undefined,
          index: topicIndex,
          groupIndex: groupIndex,
          answerCheck: type === 'edit' ? (topicItem?.answer ? (topicItem?.answer?.split(',')) : '') : topicItem?.answerCheck,
          options: type === 'edit' ? (topicItem?.options ? (JSON.parse(topicItem?.options)) : '') : topicItem?.options,
          contentUrl: type === 'edit' ? (topicItem?.contentUrl ? (topicItem?.contentUrl?.split(',')) : '') : topicItem?.contentUrl,
          analysisUrl: type === 'edit' ? (topicItem?.analysisUrl ? (topicItem?.analysisUrl.split(',')) : '') : topicItem?.analysisUrl
        }
      })
    },
    // 调整顺序
    _adjustOrderBtn () {
      if (!this.questionsGroupList.length) {
        return this.$message.error('您还未添加题目！')
      }
      this.adjustOrderShow = true
    },
    _selectTopicBankBtn () {
      // 点击从题库中选择
      this.selectTopicShow = true
      // 当还未添加分组去选择题目--则默认为不分组
      if (this.questionsGroupList.length === 0) {
        this.isFirstSelectType = 'noDivideGroup'
        this.groupIndexActive = 0
        this.divideGroupArr.groupName = ''
        this.questionsGroupList[0] = this.divideGroupArr
      }
    },
    // 题库选择提交
    _selectQuestionData (data) {
      let newArr = data.map((item) => {
        return {
          ...item,
          answerCheck: item.answer ? item.answer.split(',') : item.answer,
          options: item.options ? JSON.parse(item.options) : item.options,
          contentUrl: item.contentUrl ? item.contentUrl.split(',') : [],
          analysisUrl: item.analysisUrl ? item.analysisUrl.split(',') : [],
          isFromQuestionBank: true// 是来自题库的题 --保存时题库id为null
        }
      })
      newArr = newArr.filter((item) => item.id && this.selectTopicListArrId.indexOf(item.id) === -1)
      // console.log(newArr, this.questionsGroupList[this.groupIndexActive].questionInfo, 'newArrnewArr11')
      this.questionsGroupList[this.groupIndexActive].questionInfo.push.apply(this.questionsGroupList[this.groupIndexActive].questionInfo, newArr)
      this.selectTopicListArr.push.apply(this.selectTopicListArr, newArr)
      this.selectTopicListArrId = this.selectTopicListArr.map((item) => {
        return item.id
      })
      this.selectTopicListArr = []
      this.questionsGroupList.forEach((groupInfo, groupIndex) => {
        this._getNeedShowData(this.questionsGroupList, groupInfo, groupIndex)// 回显所需数据格式（整个数组，其中一个整个分组，分组下标）
      })
      // 删除时把this.selectTopicListArrId中题目id删掉，确保删除后能再次添加
      this._computeTopicNum(this.questionsGroupList)// 计算题目总数量，题目总分数
    },
    // 数组对象去重
    _deduplicate (arr) {
      const map = new Map()
      for (const item of arr) {
        if (!map.has(item.id)) {
          map.set(item.id, item)
        }
      }
      return [...map.values()]
    },
    // 调整顺序确定
    _adjustTopicOrder (data) {
      this.questionsGroupList = JSON.parse(JSON.stringify(data))
      // console.log(this.questionsGroupList, 'this.questionsGroupList')
    },
    // 分值调整
    _scoreAdjust () {
      this.scoreAdjustShow = true
    },
    // 编辑列表
    editOption (item, index) {
      this.editOptionIndex = index
      this.$refs.topicOptionRef.form = item
    },
    // 点击左边题类型
    _handlerType (item) {
      console.log(this.questionsGroupList, item.questionType)
      /** 注解：1、首先判断首次添加的试题是否有分组，isFirstSelectType首次添加类型
       *      2、首次选择单个试题类型，未添加分组，后续不允许添加分组（试题不可在分组外）
       *      3、首次添加有分组， 分组没有取消选中，则试题一定有分组，
       * 新增时，首次是不分组，删除不分组下所有的试题，则可以添加分组，
       * 编辑时，详情里边是分组，删除不分组下所有的实体，则可以添加分组
       */
      const tempTopicData = this._getTopicDataByType(item.questionType)
      console.log(tempTopicData, 'tempTopicData')
      // 1、如果首先点击的不是分组，则不允许选择分组,首次点击说明试题数组的长度为空
      if ((this.questionsGroupList.length === 0 && item.questionType !== 'GROUP_TYPE')) {
        this.isFirstSelectType = 'noDivideGroup'
        // 右侧字段属性展示--回显
        this.chooseTypeItem = {
          questionType: item.questionType,
          questionTypeValue: item.questionTypeValue
        }
      }
      // 1、 如果首先点击是分组，则只能往某个分组里添加试题
      if (this.questionsGroupList.length === 0 && item.questionType === 'GROUP_TYPE') {
        this.isFirstSelectType = 'divideGroup'
        this.groupIndexActive = 0
      }
      // ------------------------------
      if ((this.isFirstSelectType === 'noDivideGroup') &&
      (this.questionsGroupList.length === 1 && this.questionsGroupList[0].groupName === '' && this.questionsGroupList[0]?.questionInfo.length === 0)) {
        // 如果新建首先是不分组情况，在删除不分组下面的所有试题，要保证能够分组
        this.isFirstSelectType = 'divideGroup'
        this.groupIndexActive = 1
        // this.questionsGroupList = []
      }
      // ------------------------------
      // 2、如果首先点击的不是分组，就把后面添加的数据放在一个空组里
      if (this.isFirstSelectType === 'noDivideGroup') {
        if (item.questionType === 'GROUP_TYPE') {
          return this.$message.error('已有试题存在，不可添加分组！')
        }
        this.groupIndexActive = 0
        this.divideGroupArr.groupName = ''
        this.divideGroupArr.questionInfo.push(tempTopicData)
        this.questionsGroupList[0] = this.divideGroupArr
      }
      // 2、 如果首先点击的是分组，则必须选中一个分组去往组里边添加试题
      if (this.isFirstSelectType === 'divideGroup') {
        // 如果首次点击是分组，后需点击不是分组，则是往某个分组中添加试题
        if (item.questionType !== 'GROUP_TYPE') {
          this.questionsGroupList[this.groupIndexActive].questionInfo.push(tempTopicData)
        }
        // 首次点击是分组，后续点击分组，则是添加多个分组
        if (item.questionType === 'GROUP_TYPE') {
          // 右侧字段属性展示--回显
          this.chooseTypeItem = {
            questionType: item.questionType,
            questionTypeValue: item.questionTypeValue
          }
          this.groupIndexActive = this.questionsGroupList.length
          const questionsGroup = {
            groupName: '分组名称',
            groupIndex: this.questionsGroupList.length,
            questionInfo: []
          }
          this.paperGroupNameInfo = questionsGroup
          this.questionsGroupList.push(questionsGroup)
        }
      }
      this.questionsGroupList.forEach((groupInfo, groupIndex) => {
        this._getNeedShowData(this.questionsGroupList, groupInfo, groupIndex)// 新增回显所需数据格式（整个数组，其中一个整个分组，分组下标）
      })
      // for (let i = 0; i < this.questionsGroupList.length; i++) {
      //   if (this.questionsGroupList[i].questionInfo && this.questionsGroupList[i].questionInfo.length > 0) {
      //     this.questionsGroupList[i].questionInfo = this.questionsGroupList[i].questionInfo.map((item) => {
      //       return {
      //         ...item,
      //         groupIndex: this.questionsGroupList[i].groupIndex
      //       }
      //     })
      //   }
      // }
      this._computeTopicNum(this.questionsGroupList)// 计算题目总数量，题目总分数
      this.$nextTick(() => {
        this._quickPositionBtn(this.questionsGroupList.length - 1, (this.questionsGroupList[this.questionsGroupList.length - 1].questionInfo.length) - 1)
      })
    },
    // 预览试卷
    _previewPaper () {
      this.previewExamShow = true
    },
    // 保存题目
    _savePaperTopicData () {
      const param = {
        id: this.$route.query.paperId,
        mark: this.topicTotalScore, // 试卷总分
        questionNumber: this.topicTotalNumber, // 试题数量
        questions: [],
        optInfos: [{
          optType: 4,
          optContent: `${this.$store.state.userList.userName}修改了试卷内容`
        }]
      }
      let questionArr = JSON.parse(JSON.stringify([...this.questionsGroupList, ...this.groupDeleteArr, ...this.questionsSingleGroupCopy]))// 删除整个分组
      // 单个删除的题要放在对应分组里面---开始
      questionArr.forEach((questionItem, questionIndex) => {
        // console.log(questionItem, 'questionItem', this.topicDeleteArr, 'kkkk')
        if (questionItem.detailDeleteId !== undefined) {
          this.topicDeleteArr.forEach((item, index) => {
            if (item.groupIndex === questionItem.detailDeleteId) {
              questionItem.questionInfo.push(item)
            }
          })
        }
      })
      const tempArr = questionArr.filter((item) => item.groupName !== '' || item.questionInfo.length !== 0)
      questionArr = JSON.parse(JSON.stringify(tempArr))// (无分组的试卷中，没有题目)的传参无效--进行过滤
      // 单个删除的题要放在对应分组里面---结束
      param.questions = questionArr.map((item) => {
        return {
          groupName: item.groupName,
          groupSort: item.groupIndex,
          questionInfo: item.questionInfo.map((infoItem, infoIndex) => {
            return {
              ...infoItem,
              questionInfoId: infoItem.id && !infoItem.isFromQuestionBank ? infoItem.id : null, // 后端要求:如果是从题库选择的题目，题目id必须传null(使用isFromQuestionBank进行判断)
              sort: infoIndex,
              deflag: item.deflag,
              relId: infoItem.relId || null,
              contentUrl: infoItem.contentUrl ? infoItem.contentUrl.join(',') : '',
              options: JSON.stringify(infoItem.options),
              analysisUrl: infoItem.analysisUrl ? infoItem.analysisUrl.join(',') : ''
            }
          })
        }
      })
      if (param.questions.length === 0) {
        return this.$message.error('请添加题目！')
      }
      // 校验填空题---的空与答案的数量是否一致--start
      const tempQuestionAllArr = JSON.parse(JSON.stringify(param.questions))
      const tempAllTopicList = []
      tempQuestionAllArr.forEach((resOne) => {
        tempAllTopicList.push(...resOne.questionInfo)
      })
      let fillIndex = 0
      for (let i = 0; i < tempAllTopicList.length; i++) {
        // 校验填空题---的空与答案的数量是否一致---------------------
        if (tempAllTopicList[i].questionType === 'GAP_FILLING_TYPE') {
          fillIndex = fillIndex + 1
          tempAllTopicList[i].fillAnswer = tempAllTopicList[i].title.split('_').map((item) => { return { answer: '' } })
          const fillNum = tempAllTopicList[i].fillAnswer.length - 1
          tempAllTopicList[i].answer = tempAllTopicList[i].answer ? tempAllTopicList[i].answer.replace(/\。$/, '') : ''// 去掉答案尾部的句号
          // console.log(tempAllTopicList[i].answer, 'tempAllTopicList[i].answer')
          const answerNum = tempAllTopicList[i].answer.split('。').length
          if (tempQuestionAllArr.length === 1) {
            // console.log(fillNum, answerNum, fillIndex, resTwoIndex, '无分组校验填空题')
            if (fillNum === 0) {
              return this.$message.error('第' + (i + 1) + '题为填空题，题目不能没有填空符！')
            }
            if (fillNum !== answerNum) {
              return this.$message.error('第' + (i + 1) + '题为填空题，题目填空符与答案个数不匹配！')
            }
          }
          if (tempQuestionAllArr.length > 1) {
            // console.log(fillNum, answerNum, fillIndex, resTwoIndex, '有分组校验填空题')
            if (fillNum === 0) {
              return this.$message.error('填空题第' + fillIndex + '题，题目不能没有填空符！')
            }
            if (fillNum !== answerNum) {
              return this.$message.error('填空题第' + fillIndex + '题，题目填空符与答案个数不匹配！')
            }
          }
        }
        // 题目-----校验
      }
      // 校验填空题---的空与答案的数量是否一致--end
      getPaperContentEditApi(param).then((res) => {
        if (res.code === 200) {
          this.$message.success('新增成功！')
          this.$router.go(-1)
        }
      })
    },
    _backBtn () {
      this.$router.go(-1)
    },
    // 新增小题
    // 接收子组件提交的表单
    _getAddOption (data) {
      // 编辑
      if (this.editOptionIndex) {
        this.optionList[this.editOptionIndex] = data
      } else {
        this.optionList.push(data)
      }
    },
    // 选择左侧类型
    _getTopicDataByType (type) {
      let questionTypeData
      switch (type) {
        case 'SINGLE_CHOICE_TYPE':
          questionTypeData = JSON.parse(JSON.stringify(this.selectListData))
          questionTypeData.answerCheck = questionTypeData.answer.split(',')
          break
        case 'MULTIPLE_CHOICE_TYPE':
          questionTypeData = JSON.parse(JSON.stringify(this.selectListData))
          questionTypeData.questionTypeValue = '多项选择'
          questionTypeData.questionType = type
          questionTypeData.answer = '选项1,选项2'
          questionTypeData.answerCheck = questionTypeData.answer.split(',')
          break
        case 'JUDGMENT_TYPE':
          questionTypeData = JSON.parse(JSON.stringify(this.selectListData))
          questionTypeData.questionType = type
          questionTypeData.questionTypeValue = '判断题'
          questionTypeData.options = [
            { optionName: '正确', label: '选项1' },
            { optionName: '错误', label: '选项2' }]
          questionTypeData.answer = '选项1'
          questionTypeData.answerCheck = questionTypeData.answer.split(',')
          break
        case 'GAP_FILLING_TYPE':
          questionTypeData = JSON.parse(JSON.stringify(this.selectListData))
          questionTypeData.questionType = type
          questionTypeData.questionTypeValue = '填空题'
          questionTypeData.options = []
          questionTypeData.answer = '正确答案一。正确答案二'
          questionTypeData.analysis = '正确答案一。正确答案二'
          break
        case 'ESSAY_QUESTION_TYPE':
          questionTypeData = JSON.parse(JSON.stringify(this.selectListData))
          questionTypeData.questionType = type
          questionTypeData.questionTypeValue = '问答题'
          questionTypeData.options = []
          break
        case 'GROUP_TYPE':
          questionTypeData = JSON.parse(JSON.stringify(this.selectListData))
          questionTypeData.questionType = type
          questionTypeData.questionTypeValue = '分组'
          questionTypeData.options = []
          break
        default:
          break
      }
      return questionTypeData
    },
    // 点击列表中某个题目
    _changeTopicType (item) {
      this.chooseTypeItem = {
        questionType: item.questionType,
        questionTypeValue: item.questionTypeValue
      }
      this.groupIndexActive = item.groupIndex
      this.currentTopicData = { ...item, groupName: '' }
    },
    // 选择 分组名称
    _selectGroupName (item, index) {
      this.groupIndexActive = index
      this.chooseTypeItem = {
        questionType: 'GROUP_TYPE',
        questionTypeValue: '分组'
      }
      this.paperGroupNameInfo = this.questionsGroupList[index]
    },
    // 分组名称输入
    _topicGroupNameInput (item) {
      this.questionsGroupList[item.groupIndex].groupName = item.groupName
      this.$set(this.questionsGroupList, item.groupIndex, { ...this.questionsGroupList[item.groupIndex], groupName: this.questionsGroupList[item.groupIndex].groupName })
    },
    // 题目输入
    _topicTitleInput (item) {
      const groupIndex = this.groupIndexActive ? this.groupIndexActive : 0
      this.$nextTick(() => {
        this.questionsGroupList[groupIndex].questionInfo[item.index].title = item.title
      })
      this.$set(this.questionsGroupList[groupIndex].questionInfo, item.index,
        { ...this.questionsGroupList[groupIndex].questionInfo[item.index], title: this.questionsGroupList[groupIndex].questionInfo[item.index].title })
    },
    // 题目说明输入
    _topicContentInput (item) {
      const groupIndex = this.groupIndexActive ? this.groupIndexActive : 0
      this.$nextTick(() => {
        this.questionsGroupList[groupIndex].questionInfo[item.index].content = item.content
      })
      this.$set(this.questionsGroupList[groupIndex].questionInfo, item.index,
        { ...this.questionsGroupList[groupIndex].questionInfo[item.index], content: this.questionsGroupList[groupIndex].questionInfo[item.index].content })
    },
    // 分数输入
    _topicScoreInput (item) {
      const groupIndex = this.groupIndexActive ? this.groupIndexActive : 0
      this.$nextTick(() => {
        this.questionsGroupList[groupIndex].questionInfo[item.index].score = item.score
        this._computeTopicNum(this.questionsGroupList)// 计算题目总数量，题目总分数
      })
      this.$set(this.questionsGroupList[groupIndex].questionInfo, item.index,
        { ...this.questionsGroupList[groupIndex].questionInfo[item.index], score: this.questionsGroupList[groupIndex].questionInfo[item.index].score })
    },
    // 选项输入
    _optionItemInput (item) {
      const groupIndex = this.groupIndexActive ? this.groupIndexActive : 0
      this.$nextTick(() => {
        this.questionsGroupList[groupIndex].questionInfo[item.index].options[item.opIndex].optionName = item.options[item.opIndex].optionName
      })
      this.$set(this.questionsGroupList[groupIndex].questionInfo, item.index,
        { ...this.questionsGroupList[groupIndex].questionInfo[item.index], options: this.questionsGroupList[groupIndex].questionInfo[item.index].options })
    },
    // 选项添加
    _optionItemAddOrDelete (item) {
      const groupIndex = this.groupIndexActive ? this.groupIndexActive : 0
      this.$nextTick(() => {
        this.questionsGroupList[groupIndex].questionInfo[item.index].options = item.options
      })
      this.$set(this.questionsGroupList[groupIndex].questionInfo, item.index,
        { ...this.questionsGroupList[groupIndex].questionInfo[item.index], options: this.questionsGroupList[groupIndex].questionInfo[item.index].options })
    },
    // 单选组(单选题or判断题)
    _radioSelectInput (item) {
      const groupIndex = this.groupIndexActive ? this.groupIndexActive : 0
      this.$nextTick(() => {
        this.questionsGroupList[groupIndex].questionInfo[item.index].answer = item.answer
      })
      this.$set(this.questionsGroupList[groupIndex].questionInfo, item.index,
        { ...this.questionsGroupList[groupIndex].questionInfo[item.index], answer: this.questionsGroupList[groupIndex].questionInfo[item.index].answer })
    },
    // 复选项
    _checkSelectChange (item) {
      const groupIndex = this.groupIndexActive ? this.groupIndexActive : 0
      this.$nextTick(() => {
        this.questionsGroupList[groupIndex].questionInfo[item.index].answerCheck = item.answerCheck
        this.questionsGroupList[groupIndex].questionInfo[item.index].answer = item.answerCheck.join(',')
      })
      this.$set(this.questionsGroupList[groupIndex].questionInfo, item.index,
        {
          ...this.questionsGroupList[groupIndex].questionInfo[item.index],
          answerCheck: this.questionsGroupList[groupIndex].questionInfo[item.index].answerCheck,
          answer: this.questionsGroupList[groupIndex].questionInfo[item.index].answer
        })
    },
    // 答案
    _answerInput (item) {
      const groupIndex = this.groupIndexActive ? this.groupIndexActive : 0
      this.$nextTick(() => {
        this.questionsGroupList[groupIndex].questionInfo[item.index].answer = item.answer
      })
      this.$set(this.questionsGroupList[groupIndex].questionInfo, item.index,
        { ...this.questionsGroupList[groupIndex].questionInfo[item.index], answer: this.questionsGroupList[groupIndex].questionInfo[item.index].answer })
    },
    // 答案解析
    _analysisInput (item) {
      const groupIndex = this.groupIndexActive ? this.groupIndexActive : 0
      this.$nextTick(() => {
        this.questionsGroupList[groupIndex].questionInfo[item.index].analysis = item.analysis
      })
      this.$set(this.questionsGroupList[groupIndex].questionInfo, item.index,
        { ...this.questionsGroupList[groupIndex].questionInfo[item.index], analysis: this.questionsGroupList[groupIndex].questionInfo[item.index].analysis })
    },
    // 其他说明
    _otherInstructionsInput (item) {
      const groupIndex = this.groupIndexActive ? this.groupIndexActive : 0
      this.$nextTick(() => {
        this.questionsGroupList[groupIndex].questionInfo[item.index].otherInstructions = item.otherInstructions
      })
      this.$set(this.questionsGroupList[groupIndex].questionInfo, item.index,
        { ...this.questionsGroupList[groupIndex].questionInfo[item.index], otherInstructions: this.questionsGroupList[groupIndex].questionInfo[item.index].otherInstructions })
    },
    // 题目说明---上传图片
    _topicPictureUpload (item) {
      const groupIndex = this.groupIndexActive ? this.groupIndexActive : 0
      this.$nextTick(() => {
        this.questionsGroupList[groupIndex].questionInfo[item.index].contentUrl = item.contentUrl
      })
      this.$set(this.questionsGroupList[groupIndex].questionInfo, item.index,
        { ...this.questionsGroupList[groupIndex].questionInfo[item.index], contentUrl: this.questionsGroupList[groupIndex].questionInfo[item.index].contentUrl })
    },
    // 答案解析---上传图片
    _analysePictureUpload (item) {
      const groupIndex = this.groupIndexActive ? this.groupIndexActive : 0
      this.$nextTick(() => {
        this.questionsGroupList[groupIndex].questionInfo[item.index].analysisUrl = item.analysisUrl
      })
      this.$set(this.questionsGroupList[groupIndex].questionInfo, item.index,
        { ...this.questionsGroupList[groupIndex].questionInfo[item.index], analysisUrl: this.questionsGroupList[groupIndex].questionInfo[item.index].analysisUrl })
    },
    // 快速定位
    _quickPositionBtn (questionIndex, topicIndex) {
      console.log(questionIndex, topicIndex, 'questionIndex, topicIndex')
      const dom = document.getElementById(`topicLink${questionIndex}${topicIndex}`)
      console.log(dom, 'dom')
      dom.scrollIntoView(true)
      const scrolledY = window.scrollY
      if (scrolledY) {
        window.scroll(0, scrolledY - 60) // 防止顶部有悬浮窗
      }
    },
    // 计算题目数量，题目分数
    _computeTopicNum (questionsGroupArr) {
      console.log(questionsGroupArr, 'questionsGroupArr')
      // 计算题目数量，题目分数
      this.topicTotalNumber = 0
      this.topicTotalScore = 0
      questionsGroupArr.forEach((groupItem) => {
        this.topicTotalNumber += Number(groupItem.questionInfo.length || 0)
        for (let i = 0; i < groupItem.questionInfo.length; i++) {
          this.topicTotalScore += Number(groupItem.questionInfo[i].score)
        }
      })
    },
    // 分值调整确认
    _scoreAdjustConfirm (data) {
      console.log(data)
      let singleScore = ''
      let judgeScore = ''
      let multiScore = ''
      data.forEach((scoreItem) => {
        if (scoreItem.questionType === 'SINGLE_CHOICE_TYPE') {
          singleScore = scoreItem.score
        }
        if (scoreItem.questionType === 'JUDGMENT_TYPE') {
          judgeScore = scoreItem.score
        }
        if (scoreItem.questionType === 'MULTIPLE_CHOICE_TYPE') {
          multiScore = scoreItem.score
        }
      })
      this.questionsGroupList.forEach((groupItem, groupIndex) => {
        groupItem.questionInfo.forEach((topicItem, topicIndex) => {
          if (topicItem.questionType === 'SINGLE_CHOICE_TYPE' && singleScore) {
            topicItem.score = singleScore
          }
          if (topicItem.questionType === 'JUDGMENT_TYPE' && judgeScore) {
            topicItem.score = judgeScore
          }
          if (topicItem.questionType === 'MULTIPLE_CHOICE_TYPE' && multiScore) {
            topicItem.score = multiScore
          }
        })
      })
      setTimeout(() => {
        this._computeTopicNum(this.questionsGroupList)
      }, 50)
    }
  }
}
</script>
<style scoped lang="scss">
  .second-level{
    border-bottom: 1px solid #F3F3F3;
    .return-tit{
      margin-bottom: 0;
    }
  }
  .addTopic{
    background: #fff;
    .content{
      height: calc(100vh - 274px);
      padding: 1px 5px 0;
    }
  }
  .left{
    overflow-y: scroll;
    width: 240px;
    padding: 16px 12px 0px 0px;
    .history-version{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size:14px;
      margin-bottom: 16px;
    }
    .title{
      font-size: 16px;
      color: #000;
      border-left: 3px solid #0089FF;
      padding-left: 8px;
      line-height: 16px;
    }
    .sm-tit{
      font-size: 14px;
      color: #666;
      margin: 16px 0;
    }
    .topic-type-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      >div{
        width: 105px;
        border: 1px solid #DCDCDC;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px;
        margin-bottom: 12px;
        cursor: pointer;
        img{
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }
      }
    }
  }
  .middle{
    overflow-y: scroll;
    border-left: 1px solid #F3F3F3;
    border-right: 1px solid #F3F3F3;
    position: relative;
    // 头部
    .paper-top-content-main{
      position: sticky;
      background-color: #fff;
      top: 0px;
      padding-top:16px;
      z-index: 99;
    }
    .paper-top-content{
      width: 100%;
      display: flex;
      margin-bottom: 16px;
      height:44px;
      line-height: 44px;
      .paper-top-content-left{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: linear-gradient(to bottom, #F0F8FF,#FFFFFF);
        border: 1px solid;
        border-image: linear-gradient(to bottom, #E7F4FF, #D1EBFF) 1;
        padding-left: 24px;
        .topic-title{
          flex: 1;
          font-size: 14px;
          font-weight: 400;
          color: rgba(0,0,0,0.85);
        }
      }
      .paper-top-content-right{
        flex: 1;
        margin-left: 24px;
      }
    }
  }
  .right{
    overflow-y: scroll;
    width: 300px;
    padding: 16px;
    // 历史版本
    .history-box{
      font-size: 14px;
      .history-title{
        color: rgba(0,0,0,0.9);
        font-weight: 500;
        margin-bottom: 14px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .icon{
          color: #666666;
          font-size: 17px;
        }
      }
      .history-item{
        color: rgba(0,0,0,0.85);
        .history-item-date{
          font-weight: 500;
          height: 26px;
          line-height: 26px;
          background-color:#F6F7F9;
          margin: 10px 0px;
          padding-left: 8px;
        }
        .history-item-child{
          font-weight: 400;
          .child-current{
            background-color: #FFE9DE;
            color:#F35F0C;
            font-size: 12px;
            width: 64px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
          }
          .child-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 26px;
            line-height: 26px;
            background-color:#F6F7F9;
            margin-bottom: 10px;
            padding:0px 8px 0px 28px;
          }
        }
      }
    }
  }
  .middle{
    /deep/.el-radio,/deep/.el-checkbox{
      display: block;
      margin: 0px 0px 16px 0;
    }
  }
  .footer-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px 0;
    box-shadow: 0px -4px 8px 0px rgba(0,0,0,0.04);
  }
  .upload-block{
    width: 90px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 4px;
    color: #0089FF;
    cursor: pointer;
    border: 1px dotted #0089FF;
    margin-top: 8px;
    background: rgba(0, 137, 255, 0.05);
    i{
      margin-right: 3px;
    }
  }
  // 分组激活
  .groupIndexActiveStyle{
    // background: linear-gradient(to bottom, #F0F8FF,#FFFFFF);
    border: 1px solid;
    border-image: linear-gradient(to bottom, #E7F4FF, #D1EBFF) 1;
    padding:4px 8px;
    margin-bottom: 16px;
  }
  .groupIndexDefault{
    padding:4px 8px;
    margin-bottom: 16px;
  }
  // 左侧快速定位
  .quick-position{
    .quick-position-content{
      display: flex;
      flex-direction: column;
      font-size: 14px;
      .position-group-title{
        color: rgba(0,0,0,0.6);
        margin-bottom: 4px;
      }
      .position-group-content{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        .position-number{
          width: 39px;
          height: 26px;
          margin-right:8px ;
          text-align: center;
          line-height: 26px;
          color: #4D4D4D;
          border:1px solid #DCDCDC;
          border-radius: 4px;
          margin-bottom: 8px;
        }
      }
    }
  }
  #dynamic-table-page{
  padding: 24px;
}
</style>
